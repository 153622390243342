import { Cookie, Flame, ListChecks } from 'lucide-react';

import { routes } from '~/utils/routes';

import type { MobileLinks, NavLink } from './types';

export const desktopLinks: NavLink[] = [
  {
    name: 'Home',
    href: routes.index,
  },
  {
    name: 'Order Now',
    href: routes.orderNow.index,
    dropdown: {
      links: [
        {
          name: 'Popular meal options',
          description:
            'Choose from a selection of our favourite and most common meals',
          icon: Flame,
          href: routes.orderNow.setMenu.index,
        },
        {
          name: 'Create your own',
          description:
            'Choose your protein, carbohydrates and salad or vegetables for each meal',
          icon: ListChecks,
          href: routes.orderNow.createYourOwn.index,
        },
        {
          name: 'Snacks, Dips and Juices',
          description:
            'Choose your favourite Snacks, Dips and Juices. These extras can be ordered with or without meals',
          icon: Cookie,
          href: routes.orderNow.snacksAndJuices.index,
        },
      ],
      footer: {
        name: 'Order Now',
        description: 'View all of our packages',
        href: routes.orderNow.index,
      },
    },
  },
  {
    name: 'FAQ',
    href: routes.faq,
  },
  {
    name: 'Nutrition',
    href: routes.nutrition,
  },
  {
    name: 'About',
    href: routes.about,
  },
  {
    name: 'Contact',
    href: routes.contact.index,
  },
];

export const mobileLinks: MobileLinks = {
  packages: [
    {
      name: 'Popular meal options',
      description:
        'Choose from a selection of our favourite and most common meals',
      icon: Flame,
      href: routes.orderNow.setMenu.index,
    },
    {
      name: 'Create your own',
      description:
        'Choose your protein, carbohydrates and salad or vegetables for each meal',
      icon: ListChecks,
      href: routes.orderNow.createYourOwn.index,
    },
    {
      name: 'Snacks, Dips and Juices',
      description:
        'Choose your favourite Snacks, Dips and Juices. These extras can be ordered with or without meals',
      icon: Cookie,
      href: routes.orderNow.snacksAndJuices.index,
    },
  ],
  main: [
    {
      name: 'Home',
      href: routes.index,
    },
    {
      name: 'Order Now',
      href: routes.orderNow.index,
    },
    {
      name: 'FAQ',
      href: routes.faq,
    },
    {
      name: 'Nutrition',
      href: routes.nutrition,
    },
    {
      name: 'About',
      href: routes.about,
    },
    {
      name: 'Contact',
      href: routes.contact.index,
    },
  ],
};
